import React from 'react'
import "../styles/terms.css"

const Terms = () => {
    return (
        <div>
            <div className='terms-title'>
                <h3 >
                    OUR TERMS AND CONDITIONS
                </h3>
            </div>
            <div className='terms-tagline'>
                <p > A terms of service agreement typically contains sections pertaining to one or more of the following topic:</p>
                <ul>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                    <li style={{ listStyle: "inside" }}>
                        Accountability for online actions, behavior, and conduct
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Terms
