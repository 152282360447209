import React from "react";
import "../styles/footer.css";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import icon from "../images/icon.png";
import email from "../images/email.png";
import phone from "../images/call.png";
import { Button } from "react-bootstrap";
import Interior from "../../src/profile.pdf";
import location from "../images/location-pin.png";
import { FaLocationArrow } from "react-icons/fa";

function Footer() {
  const form = useRef();
  // eslint-disable-next-line
  const [formOpen, setFormOpen] = useState(false);
  // eslint-disable-next-line
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9vyzaie",
        "template_9gyvzuo",
        form.current,
        "O8Gs0mH_WkVC5FGSW"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormOpen(false);

          alert("Done!");
        },
        (error) => {
          console.log(error.text);
          setFormOpen(false);
          alert("couldn't able to send message!  ");
        }
      );
  };

  return (
    <div className="footer">
      <div className="col-1 kjdhg">
        <img src="../logo.png" alt="logo" className="navbar-logo" />
        <div className="footer-description d-flex align-items-center">
          {/* <p>NEED ASSISTANCE? <br />WE’RE HERE TO HELP. </p> */}
          {/* <p><b>UNIQUE SQUARE</b></p> */}
          {/* <img
            src={location}
            alt=""
            style={{ width: "20px", height: "23px" }}
          /> */}
          <FaLocationArrow style={{color:'white'}}/>
          <p className='mx-3'>
            13 Banaswadi main road
            <br />
            Bangalore - 560005 <br /> Karnataka India.
          </p>
        </div>
      </div>
      <div className="col-2 kjdhg">
        <h3>Quick Links</h3>

        <ul>
          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/about"> About</a>
          </li>
          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/services"> Services</a>
          </li>
          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/contactus"> Contact us</a>
          </li>

          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/terms"> Terms and Conditions</a>
          </li>

          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/privacy"> Privacy Policy</a>
          </li>
        </ul>
      </div>

      <div className="col-3 kjdhg">
        <h3>Our Services</h3>
        <ul>
          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/interior"> Interior</a>
          </li>
          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/construction"> Construction</a>
          </li>
          <li>
            <span>
              <img src={icon} alt="logo" className="icon" />
            </span>
            <a href="/realestate"> Real Estates</a>
          </li>
        </ul>
      </div>

      <div className="col-3 kjdhg">
        <h3>Contact Us</h3>
        <ul>
          <li>
            <span>
              <img src={email} alt="logo" className="icon-contact" />
            </span>
            <a href="/">uniquesquare.mail@gmail.com</a>
          </li>
          <li>
            <span>
              <img src={email} alt="logo" className="icon-contact" />
            </span>
            <a href="/">info@uniquesquare.in</a>
          </li>
          <li>
            <span>
              <img src={phone} alt="logo" className="icon-contact" />
            </span>
            <a href="/">+91 98450 45081</a>
          </li>
          <li>
            <span>
              <img src={phone} alt="logo" className="icon-contact" />
            </span>
            <a href="/">+91 70269 91229</a>
          </li>
          {/* <div className="navbar-social-icon">
                        <a
                            traget="_blank"
                            href="https://www.facebook.com/"
                        >
                            <img src={fb} alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.twitter.com/"
                        >
                            <img src={twitter} alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.instagram.com/"
                        >
                            <img src={instagram} alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.linkedin.com/"
                        >
                            <img src={linkedin} alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.pinterest.com/"
                        >
                            <img src={pinrest} alt="logo1" className="footer-social-icon" />
                        </a>
                    </div> */}

          <li>
            <div className="text-center mt-3">
              <a
                href={Interior}
                download="Uniquesqure-Profile"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="" className="contact-btn">
                  Get Profile
                </Button>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Footer;
