import React from 'react';
// import { useState, useEffect } from 'react';
import "../styles/slid.css"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container } from "react-bootstrap";


const SkewedPageScroll = () => {
  // const numOfPages = 5; 
  // const animTime = 10; 
  // const transitionInterval = 4000; 
  // const [curPage, setCurPage] = useState(1);
  // const [scrolling, setScrolling] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     navigateNext();
  //   }, transitionInterval);

  //   return () => clearInterval(interval);
  // }, [curPage]); 

  // const pagination = () => {
  //   setScrolling(true);

  //   setTimeout(() => {
  //     setScrolling(false);
  //   }, animTime * 1000); 
  // };

  // const navigateNext = () => {
  //   const nextPage = curPage === numOfPages ? 1 : curPage + 1;
  //   setCurPage(nextPage);
  //   pagination();
  // };


  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <div>
    {/* <div className='container mb-3 mt-4'>
      <div className="skw-pages">
        {[...Array(numOfPages)].map((_, index) => (
          <div
            key={index}
            className={`skw-page skw-page-${index + 1} ${
              curPage === index + 1 ? 'active' : ''
            }`}
          >
            <div className="skw-page__half skw-page__half--left">
              <div className="skw-page__skewed">
                <div className="skw-page__content">
                  <h2 className="skw-page__heading">  Our Real Estate Projects</h2>
                  <p className="skw-page__description">
                    {index === 0
                      ? ''
                      : index === numOfPages - 1
                      ? ''  
                      : ''}
                  </p>
                </div>
              </div>
            </div>
            <div className="skw-page__half skw-page__half--right">
              <div className="skw-page__skewed">
                <div className="skw-page__content">
                  {index === numOfPages - 1 && (
                    <div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      
    </div> */}


                <Container className='mt-2'>
                <OwlCarousel className='owl-theme' loop margin={5} nav {...options}>
                <div class='item'>
                  <div class="card kjdhg">
                    <div class="image-box">
                      <img
                        src="../Assets/service11.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg" >
                    <div class="image-box">
                      <img
                        src="../Assets/service26.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg">
                    <div class="image-box">
                      <img
                        src="../Assets/service25.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg" >
                    <div class="image-box">
                      <img
                        src="../Assets/service12.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                   
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg">
                    <div class="image-box">
                      <img
                        src="../Assets/service27.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                   
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg">
                    <div class="image-box">
                      <img
                        src="../Assets/service28.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                   
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg">
                    <div class="image-box">
                      <img
                        src="../Assets/service30.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                   
                  </div>
                </div>

                <div class='item'>
                  <div class="card kjdhg">
                    <div class="image-box">
                      <img
                        src="../Assets/service29.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                   
                  </div>
                </div>
              </OwlCarousel>
                </Container>
              </div>
   
  );
};

export default SkewedPageScroll;
