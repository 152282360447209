import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../styles/header.css";
import { Button, Row, Modal } from "react-bootstrap";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaWhatsappSquare, FaChevronRight } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  const navigate = useNavigate();
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => {
  //   setShow1(true);
  // }

  return (
    <div
      className="headers"
      style={{
        position: "sticky",
        top: "0px",
        zIndex: "9999999",
        backgroundColor: "white",
      }}
    >
      {/* Top Nav Start */}
      <div className="top-nav-header">
        <div className="top-nav">
          <div style={{ display: "block" }}>
            <a href="/">
              <img
                src="../logo.png"
                alt="logo"
                style={{ width: "220px", height: "95px" }}
              />
              <div className="iso">ISO Certified</div>
            </a>
          </div>
        </div>

        <div className="navbar-left-content">
          <div className="row">
            <div className="col-sm-12">
              <div className="top-nav-icon">
                <a href={"https://www.facebook.com/"} target="_new">
                  <FaFacebookSquare
                    style={{
                      margin: "0px 3px",
                      color: "#ff4700e6",
                      fontSize: "22px",
                    }}
                  />
                </a>
                <a href={"https://www.instagram.com/"} target="_new">
                  <FaSquareInstagram
                    style={{
                      margin: "0px 3px",
                      color: "#ff4700e6",
                      fontSize: "22px",
                    }}
                  />
                </a>
                <a href={"https://www.twitter.com/"} target="_new">
                  <FaSquareTwitter
                    style={{
                      margin: "0px 3px",
                      color: "#ff4700e6",
                      fontSize: "22px",
                    }}
                  />
                </a>
                <a href={"https://www.whatsapp.com/"} target="_new">
                  <FaWhatsappSquare
                    style={{
                      margin: "0px 3px",
                      color: "#ff4700e6",
                      fontSize: "22px",
                    }}
                  />
                </a>
                <a href={"https://www.linkedin.com/"} target="_new">
                  <FaLinkedin
                    style={{
                      margin: "0px 3px",
                      color: "#ff4700e6",
                      fontSize: "22px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Top Nav End */}

      {["xl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="navbar">
          <Container fluid>
            <Nav.Link href="/" className="tail-text">
              <div className="nav-logo-mbl" style={{ display: "block" }}>
                <a href="/">
                  <img src="../logo.png" alt="logo" className="nav-logo-mbl" />
                  <div className="isombl">ISO Certified</div>
                </a>
              </div>
            </Nav.Link>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body style={{ padding: "5px 60px" }}>
                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  style={{ alignItems: "center" }}
                >
                  <Nav.Link href="/" className="tail-text">
                    HOME
                  </Nav.Link>
                  <Nav.Link href="/about" className="tail-text">
                    ABOUT US
                  </Nav.Link>
                  <NavDropdown
                    onClick={() => navigate("")}
                    title="SERVICES"
                    id="basic-nav-dropdown"
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="dropdown-container"
                      style={{ padding: "9px", background: "transparent" }}
                    >
                      <NavDropdown.Item
                        href="/interior"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          transition: "background-color 0.3s ease",
                        }}
                        className="dropdown-item"
                      >
                        <h5
                          style={{
                            margin: 0,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaChevronRight style={{ marginRight: "10px" }} />
                          Interior
                        </h5>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="/construction"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          transition: "background-color 0.3s ease",
                        }}
                        className="dropdown-item"
                      >
                        <h5
                          style={{
                            margin: 0,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaChevronRight style={{ marginRight: "10px" }} />
                          Constructions
                        </h5>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="/realestate"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          transition: "background-color 0.3s ease",
                        }}
                        className="dropdown-item"
                      >
                        <h5
                          style={{
                            margin: 0,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaChevronRight style={{ marginRight: "10px" }} />
                          Real Estates
                        </h5>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>

                  <Nav.Link href="/gallery" className="tail-text">
                    GALLARY
                  </Nav.Link>
                  <Nav.Link href="/clients" className="tail-text">
                    CLIENTS
                  </Nav.Link>

                  <Nav.Link href="/contactus" className="tail-text">
                    CONTACT US
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

      {/* Enquiry Modal */}

      <Modal show={show1} onHide={handleClose1} style={{ zIndex: "99999999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#083a87" }}>
            Partner Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-lg-12 mb-2">
              <label className="fw-bold">Name :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Number :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your number"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Email :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email ID"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Looking for :</label>
              <select className="form-control">
                <option>Select Job Type</option>
                <option>Full Time</option>
                <option>Part Time</option>
              </select>
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Message :</label>
              <textarea
                className="form-control"
                placeholder="Tell Us Why You want to be a Pro.."
                id="floatingTextarea"
              ></textarea>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{
              background: "#080874 ",
              color: "white",
            }}
            onClick={handleClose1}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Header;
