import React from "react";
import { useEffect } from "react";
import "../styles/about.css";
import "react-country-state-city/dist/react-country-state-city.css";
import Aos from "aos";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function About() {
  useEffect(() => {
    Aos.init();
  }, []);

  
  return (
    <div>
             {/* fixed icons  */}
             <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >

     

        <div className="about-display">
          <div className="text-center p-3">
            <span className="multicolortext">ABOUT US</span>
          </div>
          <div className="about-display-container">
            <div className="about-img-container">
              <img src="../Assets/about-img.png" alt="" className="about-img" />
            </div>
            <div className="about-img-container">
              <div className="about-content">
                <p style={{ lineHeight: "30px", paddingBottom: "20px" }}>
                  The company came into{" "}
                  <span className="multicolor-subtext">
                    {" "}
                    existence in the year 2001{" "}
                  </span>{" "}
                  with few skilled hands handling the functioning of the
                  company. In the last Twenty Three years the company’s growth has
                  been tremendous.
                </p>
                <p>
                  <span className="multicolor-subtext"> UNIQUE SQUARE</span> is
                  a professionally managed company which has been in the field
                  of project{" "}
                  <span className="multicolor-subtext">
                    {" "}
                    management consultancy, Interiors,{" "}
                  </span>
                  Architectural, construction, infrastructure, consultancy
                  services and services integration for the last Twenty Three
                  years.{" "}
                  <span className="multicolor-subtext">
                    {" "}
                    Setting Unique square{" "}
                  </span>
                  apart is our commitment to total customer satisfaction &
                  strict adherence to completion &
                  <span className="multicolor-subtext">
                    {" "}
                    delivery schedules,
                  </span>{" "}
                  no matter what the space, no matter what the need, Unique
                  square will define form to create business and working
                  environment that are{" "}
                  <span className="multicolor-subtext">
                    expressions of excellence.{" "}
                  </span>
                  In today’s world of rapidly increasing information processing,
                  one of the essential components for a company’s success is the
                  office/work environment.
                </p>
                <p>
                  <span className="multicolor-subtext"> Unique square</span> is
                  award winning company for most promising interior designing
                  and architectural company in India from
                  <span className="multicolor-subtext">
                    {" "}
                    Bloomberg/utv & rashtriya udyog ratan award from Economic
                    growth society of india.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
}
export default About;
