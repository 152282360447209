import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { Button } from "react-bootstrap";
import "../styles/banner.css";
// import { IoMdClose } from "react-icons/io";
// import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
// import { Container, Row } from "react-bootstrap";
import "react-country-state-city/dist/react-country-state-city.css";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";
import About from "./About";
import Services from "./Services";

const Banner = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const options = {
    margin: 30,
    responsiveclassName: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  
  // eslint-disable-next-line
  const [showpopup, setShowPopup] = useState(true);
  // eslint-disable-next-line
  const handleClose = (e) => {
    e.stopPropagation();
    console.log("handleClose called");
    setShowPopup(false);
  };
  return (
    <div>
      <div>
        <Carousel>
          <Carousel.Item>
            <div>
              <img
                src="../Assets/home-banner.png"
                alt="df"
                text="first slide"
                className="slider-img"
              />
            </div>            
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src="../Assets/service4.png"
                alt="df"
                text="Second slide"
                className="slider-img"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src="../Assets/service7.png"
                alt="df"
                text="Third slide"
                className="slider-img"
              />
            </div>
          </Carousel.Item>
        </Carousel>


        <div>
           {/* fixed icons  */}
        <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
        </div>
      </div>

      {/* About us  */}
      <About />

      {/* Who we are  */}
      <Services />

      {/* practices  */}
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="service-containers">
          <div className="practice-container">
            <div className="flex-1">
              <div>
                <img
                  src="../Assets/practice1.png"
                  className="img-practice"
                  alt=""
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="conetct-dipl">
                <div className="text-center p-3">
                  <span className="multicolortext"> BEST PRACTICES </span>
                </div>
                <p>
                  Our core structures are developed in line with the best
                  practices of world-renowned talent development organizations.
                </p>
              </div>
            </div>
          </div>

          <div className="practice-container mb-2">
            <div className="flex-1">
              <div className="conetct-dipl">
                <div className="text-center p-3">
                  <span className="multicolortext">
                    TWO HEADS BETTER THAN ONE
                  </span>
                </div>
                <p>
                  We endeavor to present the subject matter in an Interactive
                  and effective manner, through Architects and designers
                </p>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <img
                  src="../Assets/practice2.png"
                  className="img-practice"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="practice-container">
            <div className="flex-1">
              <div>
                <img
                  src="../Assets/practice3.png"
                  className="img-practice"
                  alt=""
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="conetct-dipl">
                <div className="text-center p-3">
                  <span className="multicolortext">
                    {" "}
                    ANALYTICS AND REPORTING
                  </span>
                </div>
                <p>
                  Our motto at Unique square is to offer right information at
                  right place and to right people for the best proven output
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Expertis  */}

      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="service-containers ">
          <div className="text-center p-3">
            <span className="multicolortext">OUR EXPERTISE</span>
          </div>
          <div>
            <div className="expertise-container">
              <div className="expertise-list">
                <div>
                  <ul className="wda">
                    <li>Interior Designing </li>
                    <li>Architectural</li>
                    <li>Construction</li>
                    <li>Real Estate Projects</li>
                    <li>Turnkey Projects</li>
                  </ul>
                </div>
              </div>

              <div className="expertise-list">
                <div className="list-content">
                  <p>
                    Our conviction lies in the fact that transformation is
                    possible through empowering people with the right skillset.
                    This translates to our developing and deploying strategic
                    knowledge initiatives that promote corporate excellence. Our
                    core structures are developed in line with the best
                    practices of world-renowned talent development
                    organizations.
                  </p>
                  <p>
                    Unique square takes great pride in its business ventures, so
                    much so that clients have been favoring us with their
                    subsequent projects too. Personal involvement, business
                    integrity & Corporate relation have been our forewords.
                  </p>
                  <p>
                    We have added value to our clients by completing a number of
                    projects.
                  </p>
                  <p>
                    <span className="multicolor-subtext">
                      No assignment is too big or too small for our expertise.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* partners  */}
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="servic-container">
          <div className="cate-0">
            <div className="text-center p-3">
              <span className="multicolortext">CLIENTS & PARTNERS </span>
            </div>{" "}
            <div>
              <div className="we-offered-service-display">
                <Container>
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={5}
                    nav
                    {...options}
                  >
                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner2.png"
                        alt="Card img cap"
                      />
                    </div>

                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner9.png"
                        alt="Card img cap"
                      />
                    </div>

                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner4.png"
                        alt="Card img cap"
                      />
                    </div>
                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner5.png"
                        alt="Card img cap"
                      />
                    </div>

                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner1.png"
                        alt="Card img cap"
                      />
                    </div>

                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner3.png"
                        alt="Card img cap"
                      />
                    </div>

                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner7.png"
                        alt="Card img cap"
                      />
                    </div>
                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner8.png"
                        alt="Card img cap"
                      />
                    </div>

                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner10.png"
                        alt="Card img cap"
                      />
                    </div>
                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner11.png"
                        alt="Card img cap"
                      />
                    </div>
                    <div className="item">
                      <img
                        className="partner-logo"
                        src="../Assets/partner6.png"
                        alt="Card img cap"
                      />
                    </div>
                  </OwlCarousel>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
