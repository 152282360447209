import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Banner from "./components/Banner";
import About from "./components/About";
import Service from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import RealEstate from "./components/RealEstate";
import Construction from "./components/Construction";
import Interior from "./components/Interior";
import Slid from "./components/Slid";
import Gallery from "./components/Gallery";
import Clients from "./components/Clients";

function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Banner />
              <Footer />
            </>
          }
        />

        <Route
          path="/about"
          element={
            <>
              <Header />
              <About />
              <Footer />
            </>
          }
        />

        <Route
          path="/services"
          element={
            <>
              <Header />
              <Service />
              <Footer />
            </>
          }
        />
        <Route
          path="/gallery"
          element={
            <>
              <Header />
              <Gallery />
              <Footer />
            </>
          }
        />
        <Route
          path="/clients"
          element={
            <>
              <Header />
              <Clients />
              <Footer />
            </>
          }
        />
        <Route
          path="/contactus"
          element={
            <>
              <Header />
              <Contact />
              <Footer />
            </>
          }
        />

        {/* Dropdown pages  */}
        <Route
          path="/realestate"
          element={
            <>
              <Header />
              <RealEstate />
              <Footer />
            </>
          }
        />


        <Route
          path="/construction"
          element={
            <>
              <Header />
              <Construction />
              <Footer />
            </>
          }
        />

        <Route
          path="/interior"
          element={
            <>
              <Header />
              <Interior />
              <Footer />
            </>
          }
        />

        <Route
          path="/slider"
          element={
            <>
              <Header />
              <Slid />
              <Footer />
            </>
          }
        />
        {/* Footer page links */}

        <Route
          path="/terms"
          element={
            <>
              <Header />
              <Terms />
              <Footer />
            </>
          }
        />

        <Route
          path="/privacy"
          element={
            <>
              <Header />
              <Privacy />
              <Footer />
            </>
          }
        />


      </Routes>
    </div>
  );
}

export default App;
