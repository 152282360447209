import React, { useEffect } from "react";
import Aos from "aos";
import "../styles/banner.css";
// import Slid from "../components/Slid";

const Construction = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  return (
    <div>
            {/* fixed icons  */}
            <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
        
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="servic-container">
          <div className="cate-0">
            <div className="text-center p-3">
              <span class="multicolortext">CONSTRUCTION</span>
            </div>

            <div className="disply-container">
              {/* <h6 className='multicolor-subtext' >
                REAL ESTATES INTERIOR CONSTRUCTION
              </h6> */}
              <p className="description">
                <span className="multicolor-subtext">Unique Square </span> was
                founded in the year 2001 with the sole aim of driving quality
                deliverables in the field of{" "}
                <span className="multicolor-subtext">
                  {" "}
                  Architecture, interiors, Construction{" "}
                </span>{" "}
                and Real Estate, we work closely with clients on delivering{" "}
                <span className="multicolor-subtext">
                  {" "}
                  specialized event{" "}
                </span>{" "}
                management services on current industry trends, on
                conceptualizing, planning, developing and managing all your
                Interior and Construction needs, we endeavor to{" "}
                <span className="multicolor-subtext">
                  {" "}
                  present the subject matter{" "}
                </span>{" "}
                in an interactive and effective manner, through our best
                Designers and architacts who are experts in their specific
                fields.
              </p>
              <p className="description">
                Our conviction lies in the fact that{" "}
                <span className="multicolor-subtext">
                  {" "}
                  transformation is possible through{" "}
                </span>{" "}
                empowering people with the{" "}
                <span className="multicolor-subtext">right skillset. </span>
                This translates to our developing and deploying strategic{" "}
                <span className="multicolor-subtext">
                  {" "}
                  knowledge initiatives{" "}
                </span>{" "}
                that promote corporate excellence. Our{" "}
                <span className="multicolor-subtext">
                  {" "}
                  Core structures{" "}
                </span>{" "}
                are developed in line with the best{" "}
                <span className="multicolor-subtext">practices</span> of
                world-renowned talent development organizations
              </p>
            </div>

            <div className="container">
              <div className="row mt-3">
                <div className="col-md-5 m-auto">
                  <img
                    className="productimg"
                    src="../Assets/service4.png"
                    alt=""
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Slid /> */}
      </div>
    </div>
  );
};

export default Construction;
