import React, { useEffect } from "react";
import Aos from "aos";
import "../styles/banner.css";
// import Slid from "../components/Slid";
import { Card } from "react-bootstrap";
// import { Button, Card, Col, Form, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

const Gallery = () => {
  // const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });
  return (
    <div>
            {/* fixed icons  */}
            <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
        
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="servic-container">
          <div className="cate-0">
            <div className="text-center p-3">
              <span class="multicolortext">OUR GALLERY</span>
            </div>

            <div style={{ margin: "10px 0px" }}>
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-4 d-flex justify-content-center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  >
                    <a href="/interior" style={{ textDecoration: "none" }}>
                      <Card
                        style={{ width: "100%", cursor: "pointer" }}
                        className="col-lg-3 mb-2 p-0"
                      >
                        <Card.Img
                          className="cardImage1"
                          variant="top"
                          src="../Assets/about-img.png"
                        />
                        <Card.Body>
                          <Card.Title style={{ color: "#010161" }}>
                            INTERIOR
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </a>
                  </div>

                  <div
                    className="col-md-4 d-flex justify-content-center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  >
                    <a href="/construction" style={{ textDecoration: "none" }}>
                      <Card
                        style={{ width: "100%", cursor: "pointer" }}
                        className="col-lg-3 mb-2 p-0"
                      >
                        <Card.Img
                          className="cardImage1"
                          variant="top"
                          src="../Assets/service4.png"
                        />
                        <Card.Body>
                          <Card.Title style={{ color: "#010161" }}>
                            CONSTRUCTION
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </a>
                  </div>

                  <div
                    className="col-md-4 d-flex justify-content-center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  >
                    <a href="/realestate" style={{ textDecoration: "none" }}>
                      <Card
                        style={{ width: "100%", cursor: "pointer" }}
                        className="col-lg-3 mb-2 p-0"
                      >
                        <Card.Img
                          className="cardImage1"
                          variant="top"
                          src="../Assets/service7.png"
                        />
                        <Card.Body>
                          <Card.Title style={{ color: "#010161" }}>
                            REAL ESTATES
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </a>
                  </div>

                  {/* <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service2.png"
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Architectural
                  </Card.Title>
                </Card.Body>
              </Card>
            </div> */}
                </div>
                {/* <div className="row">
          <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service16.png"
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Project Development
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service15.png"
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Project Consultancy
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service14.png"
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                   Project Planning
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service8.png"
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Turn key Project
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
