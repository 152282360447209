import React from "react";
import { useEffect } from "react";
import "../styles/banner.css";
import { Container } from "react-bootstrap";
import "react-country-state-city/dist/react-country-state-city.css";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Services = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 4,
      },
    },
  };
  const options1 = {
    margin: 30,
    responsiveclassName: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <div>
      {/* Who we are  */}
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="servic-container">
          <div className="cate-0">
            <div className="text-center p-3">
              <span class="multicolortext">OUR SERVICES</span>
            </div>

            <div className="disply-container">
              <h6 className="multicolor-subtext">
                INTERIOR REAL ESTATES CONSTRUCTION
              </h6>
              <p className="description">
                <span className="multicolor-subtext">Unique Square </span> was
                founded in the year 2001 with the sole aim of driving quality
                deliverables in the field of{" "}
                <span className="multicolor-subtext">
                  {" "}
                  Architecture, interiors, Construction{" "}
                </span>{" "}
                and Real Estate, we work closely with clients on delivering{" "}
                <span className="multicolor-subtext">
                  {" "}
                  specialized event{" "}
                </span>{" "}
                management services on current industry trends, on
                conceptualizing, planning, developing and managing all your
                Interior and Construction needs, we endeavor to{" "}
                <span className="multicolor-subtext">
                  {" "}
                  present the subject matter{" "}
                </span>{" "}
                in an interactive and effective manner, through our best
                Designers and architacts who are experts in their specific
                fields.
              </p>
              <p className="description">
                Our conviction lies in the fact that{" "}
                <span className="multicolor-subtext">
                  {" "}
                  transformation is possible through{" "}
                </span>{" "}
                empowering people with the{" "}
                <span className="multicolor-subtext">right skillset. </span>
                This translates to our developing and deploying strategic{" "}
                <span className="multicolor-subtext">
                  {" "}
                  knowledge initiatives{" "}
                </span>{" "}
                that promote corporate excellence. Our{" "}
                <span className="multicolor-subtext">
                  {" "}
                  Core structures{" "}
                </span>{" "}
                are developed in line with the best{" "}
                <span className="multicolor-subtext">practices</span> of
                world-renowned talent development organizations
              </p>
            </div>

            <div id="cards_landscape_wrap-2">
              <div className="service-cards">
                <div class="col-3 kjdhg" style={{ width: "" }}>
                  <a href="/interior">
                    <div class="card-flyer">
                      <div class="text-box">
                        <div class="image-box">
                          <img
                            src="../Assets/service9.png"
                            alt=""
                            className="homepage-service-img"
                          />
                        </div>

                        <div class="text-container mt-2 mb-2">
                          <h6>INTERIOR</h6>
                          <p className="description">
                            Highly qualified and
                            <span className="multicolor-subtext">
                              {" "}
                              experienced designers,{" "}
                            </span>
                            Architacts,{" "}
                            <span className="multicolor-subtext">
                              {" "}
                              project managers,
                            </span>{" "}
                            engineers, force with the reputation of working{" "}
                            <span className="multicolor-subtext">
                              {" "}
                              round-the-clock{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-3 kjdhg" style={{ width: "" }}>
                  <a href="/realestate">
                    <div class="card-flyer">
                      <div class="text-box">
                        <div class="image-box">
                          <img
                            src="../Assets/service7.png"
                            alt=""
                            className="homepage-service-img"
                          />
                        </div>

                        <div class="text-container mt-2 mb-2">
                          <h6>REAL ESTATES</h6>
                          <p className="description">
                            <span className="multicolor-subtext">
                              We Unique Square Estate{" "}
                            </span>{" "}
                            provide all your property needs be it buying,
                            selling, renting or leasing.
                            {/* With the changes evolving in the city, we help in Buying, Selling, Renting & Leasing with the highest Professional help and services assured.  */}
                            {/* <span className="multicolor-subtext">
                              aim of driving quality{" "}
                            </span>
                            deliverables in the field of{" "}
                            <span className="multicolor-subtext">
                              Architecture, interiors, and construction
                            </span>
                            we work closely with clients */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-3 kjdhg" style={{ width: "" }}>
                  <a href="/contruction">
                    <div class="card-flyer">
                      <div class="text-box">
                        <div class="image-box">
                          <img
                            src="../Assets/service4.png"
                            alt=""
                            className="homepage-service-img"
                          />
                        </div>

                        <div class="text-container mt-2 mb-2">
                          <h6>CONSTRUCTION</h6>
                          <p className="description">
                            <span className="multicolor-subtext">
                              Respected for timely{" "}
                            </span>{" "}
                            execution of Constructions and Interior While
                            maintaining high{" "}
                            <span className="multicolor-subtext">
                              {" "}
                              self-imposed quality{" "}
                            </span>
                            standards that always guarantee total
                            <span className="multicolor-subtext">
                              {" "}
                              customer satisfaction.{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* services we render */}
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div>
          <div className="text-center p-3">
            <span class="multicolortext">SERVICES WE RENDER</span>
          </div>

          <br />
          <div className="we-offered-service-display">
            <Container>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={5}
                nav
                {...options}
              >
                <div class="item">
                  <div class="card" style={{ width: "auto", height: "300px" }}>
                    <div class="image-box">
                      <img
                        src="../Assets/service9.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">INTERIOR DESIGNING</h5>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card" style={{ width: "auto", height: "300px" }}>
                    <div class="image-box">
                      <img
                        src="../Assets/service2.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">ARCHITECTURAL </h5>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card" style={{ width: "auto", height: "300px" }}>
                    <div class="image-box">
                      <img
                        src="../Assets/service4.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">CONSTRUCTION</h5>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card" style={{ width: "auto", height: "300px" }}>
                    <div class="image-box">
                      <img
                        src="../Assets/service7.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">REAL ESTATE PROJECTS</h5>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="card" style={{ width: "auto", height: "300px" }}>
                    <div class="image-box">
                      <img
                        src="../Assets/service8.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">TURNKEY PROJECT</h5>
                    </div>
                  </div>
                </div>
                {/* 
                <div class='item'>
                  <div class="card" style={{ width: "auto", height: "300px" }}>
                    <div class="image-box">
                      <img
                        src="../Assets/service3.png"
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">PROJECT EXECUTION</h5>

                    </div>
                  </div>
                </div> */}
              </OwlCarousel>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
