import React, { useEffect } from "react";
import Aos from "aos";
import "../styles/banner.css";
// import Slid from "../components/Slid";
const RealEstate = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });
  return (
    <div>
            {/* fixed icons  */}
            <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
        
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="servic-container">
          <div className="cate-0">
            <div className="text-center p-3">
              <span class="multicolortext">REAL ESTATES</span>
            </div>
            <div className="profie-content">
              <p className="description">
                We{" "}
                <span className="multicolortext" style={{ fontSize: "24px" }}>
                  {" "}
                  Unique Square Estate
                </span>{" "}
                are a premium Property consultant in Bangalore. Professionally
                established ourselves in the year 2001. <br /> With the changes
                evolving in the city, we help in Buying, Selling, Renting &
                Leasing with the highest Professional help and services assured.
                We deal in Commercial, Residential, Gated Community and Tech
                Parks, Property Management, Real Estate Advisory, Expert Legal
                opinions and documentation services, etc. <br /> We have
                well-trained and qualified executives to handle any kind of
                Requirements and needs and then narrow down suitable options to
                help select the ideal property for you. <br />
                <h5 className="multicolortext" style={{ fontSize: "21px" }}>
                  "We Unique Square Estate provide all your property needs be it
                  buying, selling, renting or leasing".
                </h5>
              </p>
              <div className="container">
                <p className="description" style={{ textAlign: "left" }}>
                  <b>Commercial - </b> Showrooms, Offices, Restaurants, Lands,
                  Plug & Play workspaces, Tech Parks etc.
                </p>

                <p className="description" style={{ textAlign: "left" }}>
                  <b>Residential - </b> Apartments, Villas, Houses, Lands,
                  Farmhouses etc.
                </p>

                <p className="description" style={{ textAlign: "left" }}>
                  <b>Renting - </b> All Rentals Apartments, Villas, Houses,
                  Showrooms, Offices, Restaurants, Lands, Plug & Play
                  workspaces, Tech Parks, Godowns, Warehouses, Land, Sheds etc.{" "}
                </p>

                <div className="mb-2">
                  <h4 style={{ display: "inline" }}>
                    <b>Property Management</b>
                  </h4>
                </div>
              </div>
              <div className="container">
                <h5 className="px-0 mx-0">Our Services</h5>
                <ul style={{ fontSize: "20px" }}>
                  <li style={{ listStyleType: "square" }}>Rentals and Sales</li>
                  <li style={{ listStyleType: "square" }}>
                    Residential, Commercial properties
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    Co-ordinating with the owners
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    Negotiating the most suitable terms
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    Mediation between parties
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    Arrangement of finance assistance
                  </li>
                  <li style={{ listStyleType: "square" }}>
                    Taking care of legal matters, paper work, deeds, titles and
                    registration and more
                  </li>
                </ul>
              </div>

              {/* <p className="description">
                We cover key localities of Frazer Town, Benson Town, Cox Town,
                Jai Bharat Nagar, Maruthi Seva Nagar, Jayamahal, Cook Town,
                Kamanahalli, Kalyan Nagar, HRBR Layout, OMBR Layout, Banaswadi,
                Kasturi Nagar, Hennur, Hebbal, Hormove, Ulsoor, Indira Nagar,
                Domlur, Cambridge Layout
              </p> */}
            </div>
            <div class="container mt-4">
              <div class="row text-center">
                <div class="col-md-6 col-lg-3">
                  <img
                    src="../Assets/home.jpg"
                    className="img-fluid"
                    style={{ height: "200px", objectFit: "cover" }}
                    alt="img1"
                  />
                  <div className="p-2">
                    <h4>Rental</h4>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <img
                    src="../Assets/gatedcommunity.jpg"
                    className="img-fluid"
                    style={{ height: "200px", objectFit: "cover" }}
                    alt="img2"
                  />
                  <div className="p-2">
                    <h4>Sales</h4>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <img
                    src="../Assets/apartment.jfif"
                    className="img-fluid"
                    style={{ height: "200px", objectFit: "cover" }}
                    alt="img3"
                  />
                  <div className="p-2">
                    <h4>Gated Community</h4>
                  </div>
                </div>
                <div class="col">
                  <img
                    src="../Assets/techimg.jpg"
                    className="img-fluid"
                    style={{ height: "200px", objectFit: "cover" }}
                    alt="img4"
                  />
                  <div className="p-2">
                    <h4>Tech Parks</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* <h6 className='multicolor-subtext' >
                REAL ESTATES INTERIOR CONSTRUCTION
              </h6> */}

            {/* <div className="container">
                            <div className="row mt-3" >
                                <div
                                    className="col-md-7 mt-3 about"
                                    style={{
                                        backgroundColor: "#1487C6",
                                    }}
                                >
                                    <div className="row">
                                        <div
                                            className="col-md-11 "
                                            data-aos="fade-up"
                                            data-aos-duration="3000"
                                        >
                                            <p
                                                className="about-desc"
                                            >
                                                <b>We are also into Packing Consumables like:</b>
                                                <br />
                                                <br />
                                                <ul>
                                                    <li>
                                                        {" "}
                                                        <b>PACKING TAPES</b> - BOPP Tapes, CLEAR Tapes, PRINTED
                                                        Tapes
                                                    </li>
                                                    <li>
                                                        <b>TAPES SOLUTIONS</b> - Filament Tapes, Masking Tapes,
                                                        Kapton Tape, Double Sided Tape, ESD Conductive Grid Tape
                                                        Etc.,
                                                    </li>
                                                    <li>
                                                        <b>PRINTING SOLUTIONS</b> - Labels/Ribbons - Thermal, Heat
                                                        resistant etc.,
                                                    </li>
                                                    <li>
                                                        <b>SAFETY SOLUTIONS:</b> - PPE’s, Safety solutions, any
                                                        type Gloves
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div className="col-md-5 text-end">
                                            <img
                                                className="productimg"
                                                src="../Assets/service5.png"
                                                alt=""
                                                style={{
                                                    width: "68%",
                                                    height: "312px",
                                                    position: "absolute",
                                                    top: "0rem",
                                                    left: "600px",
                                                    border: "3px solid #1487C6",
                                                }}
                                                data-aos="zoom-in"
                                                data-aos-duration="3000"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            {/* <Slid /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstate;
