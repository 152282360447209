import React, { useEffect } from "react";
import Aos from "aos";
import "../styles/banner.css";
// import Slid from "../components/Slid";
import { Card } from "react-bootstrap";
// import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Interior = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  return (
    <div>
            {/* fixed icons  */}
            <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
        
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
         
        <div className="servic-container">
          <div className="cate-0">
            <div className="text-center p-3">
              <span class="multicolortext">INTERIOR</span>
            </div>

            <div className="disply-container">
              <p className="description">
                <span className="multicolor-subtext">Unique Square </span> was
                founded in the year 2001 with the sole aim of driving quality
                deliverables in the field of{" "}
                <span className="multicolor-subtext">
                  {" "}
                  Architecture, interiors, Construction{" "}
                </span>{" "}
                and Real Estate, we work closely with clients on delivering{" "}
                <span className="multicolor-subtext">
                  {" "}
                  specialized event{" "}
                </span>{" "}
                management services on current industry trends, on
                conceptualizing, planning, developing and managing all your
                Interior and Construction needs, we endeavor to{" "}
                <span className="multicolor-subtext">
                  {" "}
                  present the subject matter{" "}
                </span>{" "}
                in an interactive and effective manner, through our best
                Designers and architacts who are experts in their specific
                fields.
              </p>
              <p className="description">
                Our conviction lies in the fact that{" "}
                <span className="multicolor-subtext">
                  {" "}
                  transformation is possible through{" "}
                </span>{" "}
                empowering people with the{" "}
                <span className="multicolor-subtext">right skillset. </span>
                This translates to our developing and deploying strategic{" "}
                <span className="multicolor-subtext">
                  {" "}
                  knowledge initiatives{" "}
                </span>{" "}
                that promote corporate excellence. Our{" "}
                <span className="multicolor-subtext">
                  {" "}
                  Core structures{" "}
                </span>{" "}
                are developed in line with the best{" "}
                <span className="multicolor-subtext">practices</span> of
                world-renowned talent development organizations
              </p>
            </div>
          </div>
        </div>

        
      </div>

      <div style={{ backgroundColor: "gray", margin: "10px 0px" }}>
        <div className="container">
          <div className="row" data-aos="fade-up" data-aos-duration="2000">
            <div
              className="col-md-12 mt-1 mb-4 text-center"
              style={{ paddingTop: "2rem" }}
            >
              <h2 className="text-typer-animation" style={{ color: "white" }}>
                OUR INTERIOR DESIGNS
              </h2>
            </div>
          </div>
          <div className="row" style={{ paddingBottom: "2rem" }}>
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service17.png"
                />
                {/* <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Pacakaging Solutions
                  </Card.Title>
                </Card.Body> */}
              </Card>
            </div>
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/practice2.png"
                />
              </Card>
            </div>
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service18.png"
                />
              </Card>
            </div>
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-2 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service19.png"
                />
              </Card>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-3 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service20.png"
                />
              </Card>
            </div>
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-3 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service21.png"
                />
              </Card>
            </div>
            <div
              className="col-md-3 d-flex justify-content-center mb-2 "
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-3 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service22.png"
                />
              </Card>
            </div>
            <div
              className="col-md-3 d-flex justify-content-center mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                className="col-lg-3 mb-3 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="../Assets/service23.png"
                />
              </Card>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-duration="3000">
            <div
              className="col-md-12 mt-2 text-center"
              style={{ marginBottom: "2rem" }}
            >
              <button
                className="btn btn-light viewAllProducts"
                onClick={() => navigate("/gallery")}
              >
                View All services
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interior;
