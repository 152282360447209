import React, { useEffect } from 'react'
import Aos from "aos";
import "../styles/banner.css";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Clients = () => {

    useEffect(() => {
        Aos.init();
    }, []);


    useEffect(() => {
        Aos.init();
        window.scroll(0, 0);
    });

    const options = {
        margin: 30,
        responsiveclassName: true,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };
    return (
        <div>
      {/* fixed icons  */}
      <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="https://wa.link/vfce7b"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../Assets/whatsapp-i.webp"
                style={{ width: "50px", height: "50px" }}
                alt=""
              />
            </a>
          </div>
        </div>
        
            <div>
                <div
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                >
                    <div className="servic-container">
                        <div className="cate-0">
                            <div className="text-center p-3">
                                <span className="multicolortext">CLIENTS  &  PARTNERS  </span>
                            </div>            <div>
                                <div className="we-offered-service-display">
                                    <Container>
                                        <OwlCarousel
                                            className="owl-theme"
                                            loop
                                            margin={5}
                                            nav
                                            {...options}
                                        >
                                            
                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner2.png"
                                                    alt="Card img cap"
                                                />
                                            </div>

                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner9.png"
                                                    alt="Card img cap"
                                                />
                                            </div>

                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner4.png"
                                                    alt="Card img cap"
                                                />
                                            </div>
                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner5.png"
                                                    alt="Card img cap"
                                                />
                                            </div>

                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner1.png"
                                                    alt="Card img cap"
                                                />
                                            </div>

                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner3.png"
                                                    alt="Card img cap"
                                                />
                                            </div>

                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner7.png"
                                                    alt="Card img cap"
                                                />
                                            </div>
                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner8.png"
                                                    alt="Card img cap"
                                                />
                                            </div>
                                    
                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner10.png"
                                                    alt="Card img cap"
                                                />
                                            </div>
                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner11.png"
                                                    alt="Card img cap"
                                                />
                                            </div>
                                            <div className="item">
                                                <img
                                                    className="partner-logo"
                                                    src="../Assets/partner6.png"
                                                    alt="Card img cap"
                                                />
                                            </div>
                                        </OwlCarousel>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients